<template>
  <div class="map_filter">
    <div class="opt_retail_wrap">
      <span class="opt_text">Опт</span>
      <label class="opt_retail" @click="selectDealersType()">
        <input type="checkbox"
               class="hidden partners_map_checkbox"
               value="retail"
               v-model="retail"
        />
        <span class="pseudo_checkbox"></span>
      </label>
      <span class="opt_text">Розница</span>
    </div>

    <FilterSelect
        :list="countries"
        :selectedElementId="filterData.selectedCountry"
        elementType="country"
        defaultInputValue="Выберите страну"
    />

    <FilterSelect
        :list="filteringRegions"
        :selectedElementId="filterData.selectedRegion"
        elementType="region"
        defaultInputValue="Выберите регион"
    />

    <FilterSelect
        :list="filteringCities"
        :selectedElementId="filterData.selectedCity"
        elementType="city"
        defaultInputValue="Выберите город"
    />
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import FilterSelect from "./FilterSelect";

export default {
  name: "MapFilter",
  components: {FilterSelect},

  data(){
    return{
      retail: true,
    }
  },

  computed: {
    ...mapState('PartnersMap', {
      countries: state => state.filterObjects.countries,
      filterData: state => state.filterData,
    }),

    ...mapGetters('PartnersMap', [
      "filteringRegions",
      "filteringCities",
    ]),
  },

  methods: {
    selectDealersType() {
      if( !this.retail ){
        this.filterData.selectedDealersType = 'retail';
      }else{
        this.filterData.selectedDealersType = 'wholesale';
      }
      this.$store.commit('PartnersMap/selectDealersType',)
    }
  },
}
</script>

<style></style>
