<template>
  <div id="app">
      <Partners />
  </div>
</template>

<script>
import Partners from "@/views/Partners";

export default {
  components: {Partners},
}
</script>

<style lang="scss"></style>