import Vue from 'vue'
import Vuex from 'vuex'
import PartnersMap from './modules/partners_map';

Vue.use(Vuex)

export default new Vuex.Store({
    modules:{
        PartnersMap,
    },

    state: {

    },

    mutations: {

    },

    actions: {

    }
})
