import Vue from "vue";
import App from "./App.vue";
import store from "./store";

Vue.prototype.$serverHost =
  process.env.NODE_ENV === "production" ? "" : "http://reimar.kolibri.agency";

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
