<template>
  <yandex-map
    :settings="settings"
    :coords="mapCenterCoords"
    :zoom="zoom"
    class="partners_map pop_up_scroll default_scroll"
    id="partners_map"
  >
    <ymap-marker v-for="marker in markers"
                 :coords=[marker.coordinates.lat,marker.coordinates.lng]
                 :icon="placemark.icon"
                 :marker-id="`marker-` + marker.id"
                 @click.prevent="markerClick(marker.id)"
    />
  </yandex-map>
</template>

<script>
//todo при первом клике на любой маркер, карта центруется на центральном офисе виза
const host = process.env.NODE_ENV === 'production' ? '' : 'http://reimar.kolibri.agency'

import {yandexMap, ymapMarker} from 'vue-yandex-maps'
import {mapGetters, mapState} from "vuex";

export default {
  name: "Map",
  components: {yandexMap, ymapMarker},
  data() {
    return {
      settings: {
        apiKey: this.yaMapApiKey,
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1',
      },

      placemark: {
        icon: {
          layout: 'default#imageWithContent',
          imageHref: host + '/img/placemark.png',
          imageSize: [40, 65],
          imageOffset: [0, 0],
        }
      }
    }
  },

  computed: {
    ...mapState('PartnersMap', {
      zoom: state => state.mapZoom,
      yaMapApiKey: state => state.yaMapApiKey,
    }),
    ...mapGetters('PartnersMap', {
      markers: 'getFilteredPartnersList',
      mapCenterCoords: 'getMapCenterCoords',
    }),
  },

  mounted() {
    document.addEventListener('set_map_stage', this.changeMapStageListener);
  },

  methods: {
    markerClick(id) {
      this.$store.commit('PartnersMap/selectPartner', id)
    },

    changeMapStageListener(e) {
      this.$store.commit('PartnersMap/setContactPage', !!(e && e.detail && e.detail.contacts));
    },
  }
}
</script>
