<template>
  <section>
    <div class="wrapper">
      <div class="partners_map_heading">
        <h2>Точки продаж</h2>
        <MapFilter/>
      </div>
    </div>

    <PartnersMap />
    <PartnersList/>
  </section>

</template>

<script>
import MapFilter from "@/components/Partners/Filter";
import PartnersList from "@/components/Partners/PartnersList";
import PartnersMap from "@/components/Partners/Map2";

export default {
  name: "Partners",
  components: {MapFilter, PartnersList, PartnersMap},

  mounted() {
    this.$store.dispatch('PartnersMap/loadFilter');
    this.$store.dispatch('PartnersMap/loadMapObjects');
  },
}
</script>

<style lang="scss"></style>