import axios from "axios";

const state = () => ({
  serverHost:
    process.env.NODE_ENV === "production" ? "" : "http://reimar.kolibri.agency",
  yaMapApiKey: "6a60749c-e75f-48a1-92ab-438e0024de8f",
  dataReady: false,
  contactsPage: true,

  mapMarkersData: [],

  filterObjects: {
    regions: [],
    cities: [],
    countries: [],
  },

  mapObjects: {}, // Список партнеров
  filteringPartners: [],

  centeringCoordinates: [56.839771, 60.555278], // Координаты начального позиционирования карты
  mapZoom: 10, // Координаты начального зума карты
  markerIco: {
    // Параметры иконки маркера карты
    iconImageHref:
      (process.env.NODE_ENV === "production"
        ? ""
        : "http://tevro.it-kolibri.com") +
      "/local/templates/tevro/img/tevro_point.png",
    iconLayout: "default#image",
    iconImageSize: [40, 40],
    iconImageOffset: [-20, -40],
  },
  contactMarker: {
    coordinates: { lat: 56.839771, lng: 60.555278 },
    title: "ОАО «Верх-Исетский металлургический завод»",
  },

  // Данные фильтра
  filterData: {
    selectedCountry: 6,
    selectedRegion: 9,
    selectedCity: 26,
    selectedDealersType: "retail",
    selectedPartner: null,
  },

  // Координаты регионов
  regions_data: {
    7: { lat: 56.132142, lng: 103.948625 },
    9: { lat: 59.007735, lng: 61.9316226 },
    11: { lat: 52.69969002, lng: 83.88473511 },
    13: { lat: 50.595299, lng: 36.586933 },
    15: { lat: 55.4467133, lng: 80.1043923 },
    19: { lat: 56.1288899, lng: 40.4075203 },
    21: { lat: 48.7081906, lng: 44.5153353 },
    23: { lat: 51.6605982, lng: 39.2005858 },
    27: { lat: 55.1802364, lng: 50.7263945 },
    30: { lat: 54.4319422, lng: 60.8788963 },
    34: { lat: 54.3872666, lng: 35.1889094 },
    37: { lat: 54.7574648, lng: 87.4055288 },
    48: { lat: 64.2479758, lng: 95.1104176 },
    50: { lat: 55.4481548, lng: 65.1180975 },
    52: { lat: 51.7634026, lng: 35.3811812 },
    54: { lat: 52.5264702, lng: 39.2032269 },
    58: { lat: 54.2312172, lng: 56.1645257 },
    63: { lat: 55.340396, lng: 38.2917651 },
    67: { lat: 62.2287062, lng: 70.6410057 },
    69: { lat: 55.7995159, lng: 44.0296769 },
    75: { lat: 55.5595992, lng: 46.9283535 },
    79: { lat: 55.0554669, lng: 73.3167342 },
    81: { lat: 52.7450189, lng: 36.4849627 },
    83: { lat: 51.7634026, lng: 54.6188188 },
    88: { lat: 53.1412105, lng: 44.0940048 },
    91: { lat: 58.8231929, lng: 56.5872481 },
    93: { lat: 63.1558702, lng: 32.9905552 },
    98: { lat: 47.6853247, lng: 41.8258952 },
    101: { lat: 54.3875964, lng: 41.2595661 },
    106: { lat: 60.0793208, lng: 31.8926645 },
    108: { lat: 54.2369441, lng: 44.068397 },
    104: { lat: 53.4183839, lng: 50.4725528 },
    110: { lat: 51.8369263, lng: 46.7539397 },
    112: { lat: 44.6680993, lng: 43.520214 },
    118: { lat: 52.6416589, lng: 41.4216451 },
    122: { lat: 56.9634387, lng: 66.948278 },
    125: { lat: 58.8969882, lng: 82.67655 },
    127: { lat: 54.163768, lng: 37.5649507 },
    130: { lat: 53.9793357, lng: 47.7762425 },
    140: { lat: 42.1431886, lng: 47.0949799 },
    142: { lat: 45.0525641, lng: 135 },
    144: { lat: 44.952117, lng: 34.102417 },
  },
});

const actions = {
  /**
   * Загрузка данных для фильтра
   * @param ctx
   */
  loadFilter(ctx) {
    ctx.state.dataReady = false;
    axios
      .get(ctx.state.serverHost + "/local/api/get_cities.php", {
        responseType: "json",
      })
      .then((response) => {
        if (response.data) ctx.commit("setFilterObjects", response);
      });
  },

  /**
   * Загрузка списка партнеров
   * @param ctx
   */
  loadMapObjects(ctx) {
    ctx.state.dataReady = false;
    axios
      .get(ctx.state.serverHost + "/local/api/get_map_objects.php", {
        responseType: "json",
      })
      .then((response) => {
        if (response.data) ctx.commit("setMapObjects", response);
      });
  },
};

const mutations = {
  /**
   * Загрузка объектов для фильтра из аякс запроса
   * @param state
   * @param {Object} resp       - ответ сервера
   * @param {Object} resp.data  - данные в ответе сервера
   */
  setFilterObjects: (state, resp) => {
    for (let respDataKey in resp.data) {
      if (
        resp.data.hasOwnProperty(respDataKey) &&
        state.filterObjects.hasOwnProperty(respDataKey)
      ) {
        state.filterObjects[respDataKey] = resp.data[respDataKey];
      }
    }

    state.dataReady = true;
  },

  /**
   * Загрузка данных маркеров карты из аякс запроса
   * @param {Object} resp       - ответ сервера
   * @param {Object} resp.data  - данные в ответе сервера
   */
  setMapObjects(state, resp) {
    state.mapObjects = resp.data;
    state.dataReady = true;
  },

  /**
   * Обработчик выбора элемента (страна, регион...) в фильтре
   * @param event
   */
  selectElement(state, event) {
    switch (event.type) {
      case "country":
        state.filterData["selectedCountry"] = event.id;
        state.filterData["selectedRegion"] = null;
        state.filterData["selectedCity"] = null;
        break;
      case "region":
        state.filterData["selectedRegion"] = event.id;
        state.filterData["selectedCity"] = null;

        // При выборе региона карта центрируется на нём
        if (state.regions_data[event.id]) {
          state.centeringCoordinates = [
            state.regions_data[event.id].lat,
            state.regions_data[event.id].lng,
          ];
          state.mapZoom = 7;
        }
        break;
      case "city":
        state.filterData["selectedCity"] = event.id;
        break;
    }
  },

  /**
   * Установка фильтра типа дилеров
   * @param state
   * @param {string} dealerType строка с типом дилера
   */
  selectDealersType(state, dealerType) {
    // state.filterData.selectedDealersType = dealerType;
    state.filterData.selectedPartner = null;
  },

  /**
   * Установка текущего партнёра
   * @param {number} partnerId        - Id партнёра
   */
  selectPartner(state, partnerId) {
    if (partnerId > 0 && partnerId !== state.filterData.selectedPartner) {
      state.filterData.selectedPartner = partnerId;

      // Поиск объекта партнёра по переданному id
      let selectedPartner = state.mapObjects[
        state.filterData.selectedDealersType
      ].find((partner) => +partner.id === +partnerId);
      if (selectedPartner) {
        // Проверяем все ли данные в карточке партнера заполнены и устанавливаем фильтр
        if (selectedPartner.country)
          state.filterData.selectedCountry = +selectedPartner.country;
        if (selectedPartner.region)
          state.filterData.selectedRegion = +selectedPartner.region;
        if (selectedPartner.city)
          state.filterData.selectedCity = +selectedPartner.city;

        if (selectedPartner.coordinates) {
          state.centeringCoordinates = [
            selectedPartner.coordinates.lat,
            selectedPartner.coordinates.lng,
          ];
          state.mapZoom = 17;
        }
      }
    }
  },

  /**
   * Мутация устанавливает переменную в тру или фолс в зависимости от того страница контактов или нет.
   * @param state
   * @param status
   */
  setContactPage(state, status) {
    state.contactsPage = status;
    if (status) {
      state.mapZoom = 17;
    } else {
      state.mapZoom = 10;
    }
  },
};

const getters = {
  /**
   * Отфильтрованные маркеры для карты
   * @typedef {Object} Marker
   * @returns {Array.<Marker>}
   */
  filteringMarkers(state) {
    let filteringMarkers = [];

    if (
      !state.filterData.selectedRegion &&
      state.filterData.selectedCountry === 6 &&
      state.filterObjects &&
      state.filterObjects["regions"]
    ) {
      for (let i = 0; i < state.mapObjects.length; i++) {
        if (state.mapObjects[i].type === "region") filteringMarkers.push(i);
      }
    } else if (state.filterData.selectedDealersType) {
      for (let i = 0; i < state.mapObjects.length; i++) {
        if (state.mapObjects[i].type === state.filterData.selectedDealersType)
          filteringMarkers.push(i);
      }
    }
    return filteringMarkers;
  },

  /**
   * Получаем набор маркеров согласно фильтру
   * @param state
   * @returns {*}
   */
  getFilteredPartnersList(state) {
    let partners = [];

    if (Object.keys(state.mapObjects).length > 0 && !state.contactsPage) {
      partners = state.mapObjects[state.filterData.selectedDealersType].filter(
        (partner) => {
          // Выбрана только страна
          if (
            state.filterData.selectedCountry !== null &&
            state.filterData.selectedRegion === null &&
            state.filterData.selectedCity === null &&
            +partner.country === state.filterData.selectedCountry
          )
            return true;

          // Выбраны страна и область
          if (
            state.filterData.selectedCountry !== null &&
            state.filterData.selectedRegion !== null &&
            state.filterData.selectedCity === null &&
            +partner.region === state.filterData.selectedRegion
          )
            return true;

          // Выбраны страна, область и город
          if (
            state.filterData.selectedCountry !== null &&
            state.filterData.selectedRegion !== null &&
            state.filterData.selectedCity !== null &&
            +partner.city === state.filterData.selectedCity
          )
            return true;
        }
      );
      return partners;
    } else if (state.contactsPage) {
      return [
        {
          id: 1,
          coordinates: { lat: 56.839771, lng: 60.555278 },
        },
      ];
    } else {
      return [];
    }
  },

  /**
   * Выборка списка областей для фильтра
   * @param state
   * @returns Array {*|*[]}
   */
  filteringRegions(state) {
    if (
      state.filterData["selectedCountry"] &&
      Array.isArray(
        state.filterObjects.regions[state.filterData["selectedCountry"]]
      )
    ) {
      return state.filterObjects.regions[state.filterData["selectedCountry"]];
    } else {
      return [];
    }
  },

  /**
   * Выборка списка городов для фильтра
   * @param state
   * @returns Array {*[]|*}
   */
  filteringCities(state) {
    if (
      state.filterData["selectedRegion"] &&
      Array.isArray(
        state.filterObjects["cities"][state.filterData["selectedRegion"]]
      )
    ) {
      return state.filterObjects["cities"][state.filterData["selectedRegion"]];
    } else {
      return [];
    }
  },

  /**
   * Получаем данные центра карты
   * @param state
   */
  getMapCenterCoords(state) {
    if (state.filterData.selectedPartner !== null && !state.contactsPage) {
      let partner = state.mapObjects[state.filterData.selectedDealersType].find(
        (partner) => partner.id === state.filterData.selectedPartner
      );
      return [partner.coordinates.lat, partner.coordinates.lng];
    } else if (!state.contactsPage) {
      return state.centeringCoordinates;
    } else {
      return [56.839771, 60.555278];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
